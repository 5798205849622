import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parkImages, setParkImages] = useState({});
  const { user, isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    const fetchWishlist = async () => {
      if (!isAuthenticated || !user?.id) {
        setLoading(false);
        return;
      }
  
      try {
        
        const response = await axios.get(`https://new-npdb.onrender.com/api/wishlist/${user.id}`);
        
  
        if (Array.isArray(response.data)) {
          setWishlist(response.data);
  
          const parkImagesData = {};
          for (const item of response.data) {
            const parkResponse = await axios.get(`https://new-npdb.onrender.com/api/parks/code/${item.parkCode}`);
            
            if (parkResponse.data && parkResponse.data.images.length > 0) {
              parkImagesData[item.parkCode] = parkResponse.data.images[0]?.url;
            }
          }
          setParkImages(parkImagesData);
        } else {
          setError('Unexpected response data format.');
        }
      } catch (error) {
        setError('Failed to fetch wishlist.');
        console.error("Fetch wishlist error:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchWishlist();
  }, [isAuthenticated, user?.id]);

  const handleMarkAsVisited = async (parkCode, wishlistItem) => {
    
    if (!wishlistItem || !wishlistItem.account_id) {
      console.error('Error: wishlistItem or account_id is undefined.');
      return;
    }
  
    try {
      const { account_id } = wishlistItem;
  
      const response = await axios.put(`https://new-npdb.onrender.com/api/visited/${account_id}/${parkCode}`);
      if (response.status === 200) {
        alert('Item successfully marked as visited.');
        setWishlist(wishlist.filter(item => item.parkCode !== parkCode));
      } else {
        alert('Failed to mark item as visited.');
      }
    } catch (error) {
      setError('Failed to mark item as visited.');
      console.error("Mark as visited error:", error);
    }
  };
  
  
  
  
  const handleDelete = async (parkCode) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this item from your wishlist?');
    if (!confirmDelete) return;
  
    try {
      await axios.delete(`https://new-npdb.onrender.com/api/wishlist/${user.account_id}/${parkCode}`);
      setWishlist(wishlist.filter(item => item.parkCode !== parkCode));
      alert('Item successfully deleted.');
    } catch (error) {
      setError('Failed to delete item.');
      console.error("Delete error:", error);
    }
  };
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-container">
      <div className="small-spacer"></div>
      <h1>My Wishlist</h1>
      <p className="table-sentence">Click on Park Name to see details about the park.</p>
      <div className="container shadow table-responsive font-link pt-2">
        <table className="table table-sm table-dark table-bordered">
          <thead className="table-group-divider">
            <tr className="new-color">
              <th></th>
              <th>Park Name</th>
              <th>Location</th>
              <th></th>
              <th></th>
            </tr>
          </thead >
          <tbody className="new-color">
            {wishlist.map((item) => (
              <tr className="object-fit" key={item._id}>
                <td>
                  {parkImages[item.parkCode] ? (
                    <img
                      src={parkImages[item.parkCode]}
                      alt={item.fullName}
                      style={{ height: '100px', width: 'auto' }}
                    />
                  ) : (
                    <div>No Image</div>
                  )}
                </td>
                <td>
                  <Link to={`/park/${item.parkCode}`} className="link-dark" style={{ textDecoration: "none" }}>
                    {item.fullName || 'Unknown Park'}
                  </Link>
                </td>
                <td>{item.states || 'Unknown Location'}</td>
                <td>
                <button
                    className="btn shadow btn-primary"
                    onClick={() => handleMarkAsVisited(item.parkCode, item)} // Pass 'item' as 'wishlistItem'
                  >
                    Mark Visited
                  </button>
                </td>
                <td>
                  <button
                    className="btn shadow btn-outline-primary"
                    style={{ backgroundColor: 'white' }}
                    onClick={() => handleDelete(item.parkCode)} // Use parkCode here
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="small-spacer"></div>
    </div>
  );
};

export default Wishlist;
