import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MostVisited = () => {
  const [parks, setParks] = useState([]);
  const [parkImages, setParkImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMostVisited = async () => {
      try {
        const response = await axios.get('https://new-npdb.onrender.com/api/most-visited');

        if (response.data && Array.isArray(response.data)) {
          setParks(response.data);

          // Fetch images for each park
          const parkImagesData = {};
          for (const item of response.data) {
            const parkResponse = await axios.get(`https://new-npdb.onrender.com/api/parks/code/${item.parkCode}`);

            if (parkResponse.data && parkResponse.data.images.length > 0) {
              parkImagesData[item.parkCode] = parkResponse.data.images[0]?.url; // Store the first image URL
            }
          }

          setParkImages(parkImagesData); // Update the state with park images
        } else {
          setError('Unexpected response data format.');
        }
      } catch (error) {
        setError('Failed to fetch most visited parks.');
        console.error("Fetch most visited error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMostVisited();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="page-container">
        <div className="small-spacer"></div>
        <h1>Most Visited Parks</h1>
        <p className="table-sentence">Click on Park Name to see details about the park.</p>
        <div className="container shadow table-responsive font-link pt-2">
          {parks.length === 0 ? (
            <div className="off-the-wall2">No parks have been visited yet.</div>
          ) : (
            <table className="table table-sm table-dark table-bordered">
              <thead className="table-group-divider">
                <tr className="new-color">
                  <th></th>
                  <th>Park Name</th>
                  <th>State(s)</th>
                  <th>Visit Count</th>
                </tr>
              </thead>
              <tbody className="new-color">
                {parks.map((park) => (
                  <tr className="object-fit" key={park.parkCode}>
                    <td>
                      {parkImages[park.parkCode] ? (
                        <img
                          src={parkImages[park.parkCode]}
                          alt={park.fullName}
                          style={{ height: '100px', width: 'auto' }}
                        />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/park/${park.parkCode}`}
                        className="link-dark"
                        style={{ textDecoration: 'none' }}
                      >
                        {park.fullName}
                      </Link>
                    </td>
                    <td>{park.states}</td>
                    <td>{park.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="small-spacer"></div>
      </div>
    </>
  );
}

export default MostVisited;
