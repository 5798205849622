import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

const PublicProfile = () => {
  const { username } = useParams();
  const [profileImage, setProfileImage] = useState('');
  const [reviews, setReviews] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [visitedParks, setVisitedParks] = useState([]);
  const [parkImages, setParkImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://new-npdb.onrender.com/api/user/id/${username}`);
        console.log('User data response:', response.data);
        setUserId(response.data.userId);
        setProfileImage(response.data.profileImage);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, [username]);

  // Fetch reviews when userId changes
  useEffect(() => {
    if (!userId) return;

    const fetchReviews = async () => {
      try {
        const response = await axios.get(`https://new-npdb.onrender.com/api/reviews/user/${userId}`);
        console.log('Reviews response:', response.data);
        setReviews(response.data.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setError('Failed to load reviews');
      }
    };

    fetchReviews();
  }, [userId]);

  // Fetch wishlist and visited parks when userId changes
  useEffect(() => {
    if (!userId) return;

    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`https://new-npdb.onrender.com/api/wishlist/${userId}`);
        console.log('Wishlist response:', response.data);
        setWishlist(response.data);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
        setError('Failed to load wishlist');
      }
    };

    const fetchVisitedParks = async () => {
      try {
        const response = await axios.get(`https://new-npdb.onrender.com/api/visited/${userId}`);
        console.log('Visited Parks response:', response.data);
        setVisitedParks(response.data);
      } catch (error) {
        console.error('Error fetching visited parks:', error);
        setError('Failed to load visited parks');
      }
    };

    fetchWishlist();
    fetchVisitedParks();
  }, [userId]);

  // Fetch park images when wishlist or visited parks change
  useEffect(() => {
    const allParkCodes = [
      ...wishlist.map(item => item.parkCode),
      ...visitedParks.map(park => park.parkCode)
    ];

    if (allParkCodes.length === 0) return;

    const fetchParkImages = async (parkCodes) => {
      try {
        const parkImagesData = {};
        const responses = await Promise.all(
          parkCodes.map(parkCode => axios.get(`https://new-npdb.onrender.com/api/parks/code/${parkCode}`))
        );
        console.log('Park Images responses:', responses.map(res => res.data));
        responses.forEach(response => {
          const data = response.data;
          if (data && data.images.length > 0) {
            parkImagesData[data.parkCode] = data.images[0]?.url;
          }
        });
        setParkImages(parkImagesData);
      } catch (error) {
        console.error('Error fetching park images:', error);
        setError('Failed to load park images');
      }
    };

    fetchParkImages(allParkCodes);
  }, [wishlist, visitedParks]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span key={index} className={`star ${index < rating ? 'filled' : ''}`}>
        ★
      </span>
    ));
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  
  // const fetchProfileImage = async () => {
  //   try {
  //     const response = await axios.get(`https://new-npdb.onrender.com/api/user/id/${username}`);
  //     console.log('User data response:', response.data);
  //     setProfileImage(response.data.profileImage);
  //   } catch (error) {
  //     console.error('Error fetching user data:', error);
  //     setError('Failed to load user data');
  //   } finally {
  //     setLoading(false);
  //   }
  // }


  return (
    <div className="page-container">
      <div className="small-spacer"></div>
      <h1>{username}'s Profile</h1>
      <CloudinaryContext cloudName="dxrummmws">
        <Image
          publicId={profileImage} // Use the URL directly
          className="profile-image"
        >
          <Transformation width="80" crop="scale" />
        </Image>
      </CloudinaryContext>
      <p></p>
      {/* Reviews Section */}
      <h2>Reviews</h2>
      
      {reviews.length > 0 ? (
        <div className="container shadow table-responsive font-link pt-2">
          <table className="table table-sm table-dark table-bordered">
            <thead className="table-group-divider">
              <tr className="new-color">
                <th>Park Name</th>
                <th>Rating</th>
                <th>Review</th>
              </tr>
            </thead>
            <tbody className="new-color">
              {reviews.map((review) => (
                <tr key={review._id}>
                  <td>
                    <Link to={`/park/${review.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                      {review.fullName}
                    </Link>
                  </td>
                  <td>
                    <div className="readonly-star-rating">{renderStars(review.rating)}</div>
                  </td>
                  <td>{review.review}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="off-the-wall2">
          No Reviews written yet.
        </div>
      )}

      {/* Wishlist Section */}
      <div className="small-spacer"></div>
      <h2>Wishlist</h2>
      <p className="table-sentence">Click on Park Name to see details about the park.</p>
      <p></p>
      {wishlist.length > 0 ? (
        <div className="container shadow table-responsive font-link pt-2">
          <table className="table table-sm table-dark table-bordered">
            <thead className="table-group-divider">
              <tr className="new-color">
                <th></th>
                <th>Park Name</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody className="new-color">
              {wishlist.map((item) => (
                <tr key={item._id}>
                  <td>
                    {parkImages[item.parkCode] ? (
                      <img src={parkImages[item.parkCode]} alt={item.fullName} style={{ height: '100px', width: 'auto' }} />
                    ) : (
                      <div>No Image</div>
                    )}
                  </td>
                  <td>
                    <Link to={`/park/${item.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                      {item.fullName}
                    </Link>
                  </td>
                  <td>{item.states || 'Unknown Location'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="off-the-wall2">
          No parks added to Wishlist yet.
        </div>
      )}

      {/* Visited Parks Section */}
      <div className="small-spacer"></div>
      <h2>Visited Parks</h2>
      {visitedParks.length > 0 ? (
        <div className="container shadow table-responsive font-link pt-2">
          <table className="table table-sm table-dark table-bordered">
            <thead className="table-group-divider">
              <tr className="new-color">
                <th></th>
                <th>Park Name</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody className="new-color">
              {visitedParks.map((park) => (
                <tr key={park._id}>
                  <td>
                    {parkImages[park.parkCode] ? (
                      <img src={parkImages[park.parkCode]} alt={park.fullName} style={{ height: '100px', width: 'auto' }} />
                    ) : (
                      <div>No Image</div>
                    )}
                  </td>
                  <td>
                    <Link to={`/park/${park.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                      {park.fullName}
                    </Link>
                  </td>
                  <td>{park.states || 'Unknown Location'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="off-the-wall2">
          No parks visited yet.
        </div>
      )}
      <div className="small-spacer"></div>
    </div>
  );
};

export default PublicProfile;
